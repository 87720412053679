import React, {useEffect} from 'react';
import {f7, Page, Preloader} from "framework7-react";
import {Login} from "../api/auth";
import axios from "axios";
import {getPartner, getState, isAdmin} from "../api/partner";

const Loading = () => {
    useEffect(() => {
        window.Telegram.WebApp.expand();
        async function login(initData) {
            await Login(initData);
        }

        login(window.Telegram.WebApp.initData).then(
            async () => {
                axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`

                const data = await getState()
                const admin = await isAdmin()
                console.log(admin)
                if (admin) {
                    f7.views.main.router.navigate("/admin")
                }
                else if (data.state === 1) {
                    window.Telegram.WebApp.requestContact((accepted, clb) => {
                        if (accepted) {
                            f7.views.main.router.navigate('/register', {props: {phoneNumber: clb.responseUnsafe.contact.phone_number}});
                        } else {
                            window.Telegram.WebApp.close();
                        }
                    })
                } else if (data.state === 2)
                {
                    f7.views.main.router.navigate("/clarify")
                } else if (data.state === 3) {
                    const partner = await getPartner()
                    console.log(partner)
                    if (partner?.age) {
                        f7.views.main.router.navigate("/partner")
                    } else if (partner.status === 400) {
                        f7.views.main.router.navigate("/clarify")
                    }


                }
            }
        )





    }, []);
    return (
        <Page bgColor="white">
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: "100vh"}}>
                <Preloader color={"blue"}/>
            </div>


        </Page>
    );
};

export default Loading;
