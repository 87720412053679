import './App.css';
import {
    App, f7, f7ready, View
} from "framework7-react";
import Registration from "./Screens/Registration";
import Splash from "./Screens/Splash";
import Partner from "./Screens/Partner";
import Admin from "./Screens/Admin";
import Clarify from "./Screens/Clarify";
import Loading from "./Screens/Loading";


function MyApp() {
    f7ready(() => {
    });

    const f7params = {
        name: 'My App',
        routes: [
            {
                path: '/register',
                component: Registration,
            },
            {
                path: '/splash',
                component: Splash,
            },
            {
                path: '/partner',
                component: Partner,
            },
            {
                path: '/clarify',
                component: Clarify,
            },
            {
                path: '/admin',
                component: Admin,
            },
            {
                path: '/loading',
                component: Loading,
            },

        ],
    };
  return (
      <App theme={"ios"} name="My App" {...f7params}>
          <View main url="/loading" />
      </App>
  );
}

export default MyApp;
