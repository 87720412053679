import React, {useEffect, useState} from 'react';
import {App, f7, f7ready, Page, Preloader} from "framework7-react";
import axios from "axios";
import {getPartner, getState} from "../api/partner";

const Splash = () => {
    f7ready(() => {
    })
    const [ngrok, setNgrok] = useState("")
    const [error, setError] = useState("")
    useEffect(()=> {
        window.Telegram.WebApp.MainButton.isVisible = false;
    }, [])
    /*setTimeout(() => {
        f7.views.main.router.navigate("/partner");
    }, 3000)*/

    useEffect(() => {
        const pollData = async () => {
                const data = await getState()
                if (data.state === 3) {
                    const partner = await getPartner()
                    if (partner?.age) {
                        f7.views.main.router.navigate("/partner");
                    }

                }
        };

        pollData();

        const intervalId = setInterval(pollData, 5000);

        return () => clearInterval(intervalId);
    }, []);
    return (
        <Page>
            <div className={"preloader-wrapper"} style={{
                display: 'flex',
                height: "100%",
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection:'column',
                fontSize: '2.5vw',
            }}>
                <h1>Поиск партнера / Partner qidirilmoqta</h1>
                <p style={{marginTop: -10, fontSize: '3vw'}}>Пожалуйста подождите / Iltimos kuting</p>
                <Preloader color='blue' size={30}/>
            </div>
        </Page>
    );
};

export default Splash;