import React, {useCallback, useEffect, useState} from 'react';
import male from '../assets/img_1.png'
import female from '../assets/img_2.png'
import call from '../assets/icons/call.svg'
import {f7, Page} from "framework7-react";
import Confetti from "react-dom-confetti";
import axios from "axios";
import {getPartner, getState} from "../api/partner";


const Partner = () => {
    const [ngrok,setNgrok] = useState("https://hyperflux.uz")
    const [data, setData] = useState({})
    const [adminData, setAdminData] = useState([])
    const fetchData = async () => {
            const partner = await getPartner();
            setData(partner)
            console.log(data)
        }
    const handleMainBtn = () => {
        window.Telegram.WebApp.openTelegramLink(`https://t.me/${data.username}`)
    }
    useEffect(() => {
        fetchData()
        window.Telegram.WebApp.MainButton.isVisible = true;
        window.Telegram.WebApp.MainButton.text = "Перейти в личные сообщения";
        window.Telegram.WebApp.onEvent('mainButtonClicked', handleMainBtn)
        window.Telegram.WebApp.HapticFeedback.notificationOccurred("success");

        f7.on('pageBeforeRemove', (() => {
            window.Telegram.WebApp.MainButton.isVisible = false;
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn)
        }))

        return (() => {
            window.Telegram.WebApp.MainButton.isVisible = false;
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn)
        })
    }, [data.username, ])

    useEffect(() => {
        const pollData = async () => {
            const data = await getState()
            if (data.state === 2) {
                window.Telegram.WebApp.MainButton.isVisible = false;
                window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn)
                f7.views.main.router.navigate("/clarify");
            }
            fetchData()
        };

        pollData();

        const intervalId = setInterval(pollData, 5000);

        return () => clearInterval(intervalId);

    }, [])


    const [isActive, setIsActive] = useState(false)
    setTimeout(() => {
        setIsActive(true)
    }, 100)
    const config = {
        angle: "90",
        spread: 360,
        startVelocity: 40,
        elementCount: 170,
        dragFriction: 0.12,
        duration: 5000,
        stagger: 3,
        width: "22px",
        height: "10px",
        perspective: "500px",
        colors: ["#b885ef", "#0bc3fd", "#b7f1a2", "#e87282", "#fdff6a"]
    };
    return (
        <Page  style={{position: 'fixed'}} bgColor={"white"}>
            <Confetti active={isActive} config={ config }/>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100vw',
                marginTop: 20,
                padding: 0,
            }}>
                <div
                    style={{
                        width: 150,  // Set width and height to be equal for a perfect circle
                        height: 150,
                        backgroundImage: `url(${data?.gender === "female" ? female : male})`,
                        borderRadius: "50%",  // This makes it circular
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.4)',
                    }}
                />
            </div>


                <h1 style={{marginLeft: 10, fontSize: 30, color: "#2ea4ee"}}>
                    <span className={"poppins-extrabold"}>{data?.first_name},</span>
                    <span className={"poppins-extrathin"} style={{fontSize: 25, color: "#92b7ee"}}>{" "}{data?.age}</span>
                </h1>

            <p style={{marginLeft: 10,fontSize: 16}}>
                <span style={{fontWeight: 'bold'}} className={"poppins-regular"}>Bu ID bo'yicha toping / Ищите партнера по ID: </span>
                <span style={{fontSize: 16, color:'#06933b'}} className={"poppins-regular"}> {data?.code} </span>
            </p>
            <p style={{marginLeft: 10,fontSize: 16}} className={"poppins-regular"}>
                <span style={{fontWeight: 'bold'}}>Yo'nalish / Направление: </span> {data?.job_type_id}
            </p>
                <p style={{marginLeft: 10,fontSize: 16}} className={"poppins-regular"}>
                    <span style={{fontWeight: 'bold'}}>Telefon raqam / Номер телефона: </span> {data?.phone_number}
                    <img
                        onClick={() => {
                            window.Telegram.WebApp.HapticFeedback.impactOccurred("medium");
                            window.open(`tel:${data?.phone_number}`);}}
                        style={{verticalAlign: 'text-bottom',marginTop: -3,marginLeft: 5}}
                        width={25}
                        src={call}
                    />
                </p>

            <h1 className={"poppins-bold"} style={{
                marginLeft: 10,
                fontSize: 15
            }}>
                 Men haqimda / Обо мне
            </h1>
                <p className={"poppins-regular"} style={{
                    color: 'black',
                    marginLeft: 10,
                    fontSize: 15,
                    overflow: 'visible',
                    width: '90vw',
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',}}>{data?.description ? data.description : "M'alumot yoq / Данных нет"}
                </p>

        </Page>
    );
};

export default Partner;