import axios from "axios";


const ngrok = "https://hyperflux.uz"


export const Login = async (data) => {
    try {

        delete axios.defaults.headers.common['Authorization'];

        const res = await axios.post(`${ngrok}/api/auth/token/`, {init_data: data})

        localStorage.setItem('token', res.data.access)
        console.log(data)
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
        axios.defaults.headers.common['ngrok-skip-browser-warning'] = true

        // return res.data
    } catch (e) {
        console.log(e)
    }
}

