import axios from "axios";
import {f7} from "framework7-react";


const ngrok = "https://hyperflux.uz"


export const getState = async () => {
    // axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
    // axios.defaults.headers.common['ngrok-skip-browser-warning'] = true


    try {

        const res = await axios.get(`${ngrok}/api/participant/state`)
        console.log(res.data)
        return res.data
    }
    catch (e) {
        console.log(e)
    }

}

export const postClarify = async (data) => {
    // axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
    // axios.defaults.headers.common['ngrok-skip-browser-warning'] = true


    try {

        const res = await axios.post(`${ngrok}/api/participant/agree_matching/`, data)
        // console.log(res.data)
        return res
    }
    catch (e) {
        console.log(e)
        return e.response
    }

}

export const isAdmin = async () => {
    // axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
    // axios.defaults.headers.common['ngrok-skip-browser-warning'] = true


    try {

        const res = await axios.get(`${ngrok}/api/participant/`, )
        console.log(res.data)
        return res.data.is_admin
    }
    catch (e) {
        console.log(e)
    }

}

export const getJobs = async () => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
    axios.defaults.headers.common['ngrok-skip-browser-warning'] = true


    try {

        const res = await axios.get(`${ngrok}/api/participant/job_type/`, )
        return res.data
    }
    catch (e) {
        console.log(e)
    }

}

export const getMatchingTypes = async () => {
    // axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
    // axios.defaults.headers.common['ngrok-skip-browser-warning'] = true


    try {

        const res = await axios.get(`${ngrok}/api/participant/matching_stages/`, )
        return res.data
    }
    catch (e) {
        console.log(e)
    }

}

export const startMatching = async (id) => {
    // axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
    // axios.defaults.headers.common['ngrok-skip-browser-warning'] = true


    try {

        const res = await axios.post(`${ngrok}/api/participant/matching/`, {stage: id})
        return res
    }
    catch (e) {
        console.log(e)
    }

}

export const getPartner = async () => {
    // axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
    // axios.defaults.headers.common['ngrok-skip-browser-warning'] = true


    try {

        const res = await axios.get(`${ngrok}/api/participant/my_partner/`, )
        return res.data
    }
    catch (e) {
        console.log(e)
        return e.response
    }

}

export const prepareMatching = async () => {
    // axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
    // axios.defaults.headers.common['ngrok-skip-browser-warning'] = true


    try {

        const res = await axios.post(`${ngrok}/api/participant/prepare_for_matching/`, )
        return res
    }
    catch (e) {
        console.log(e)
    }

}

export const getParticipantCount = async () => {
    // axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
    // axios.defaults.headers.common['ngrok-skip-browser-warning'] = true


    try {

        const res = await axios.get(`${ngrok}/api/participant/unique_agree_count`, )
        return res.data
    }
    catch (e) {
        console.log(e)
    }

}


