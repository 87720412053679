
import {
    App,
    BlockFooter, BlockHeader,
    BlockTitle, f7, f7ready,
    Link,
    List,
    ListInput, ListItem, Page, Popover,
} from "framework7-react";
import React, {useCallback, useEffect, useMemo, useReducer, useRef, useState} from "react";
import axios from "axios";
import {Login} from "../api/auth";
import {getJobs, getState, isAdmin} from "../api/partner";


function Registration(props) {
    f7ready(() => {
    });
    const [jobsVal, setJobsVal] = useState([])
    const [ngrok,setNgrok] = useState("https://hyperflux.uz")
    const [isSubmitting, setIsSubmitting] = useState(false)

    const isSubmittingRef = useRef(false);

    const [registrationError, setRegistrationError] = useState("")





    const fetchJobs = async () => {
        const jobs = await getJobs();
        setJobsVal(jobs)
    }
    useEffect(() => {
        if (localStorage.getItem("token") && jobsVal?.length === 0) {
            fetchJobs()
            console.log(jobsVal)
        }

    }, [fetchJobs, jobsVal, localStorage.getItem("token")])




    const initialState = {
        name: '',
        lastName: '',
        username: '',
        userID: '',
        birthday: null,
        description: '',
        gender: '',
        job: '',
        phoneNumber: `+${props.phoneNumber}`,
        errors: {},
    };

    function formReducer(state, action) {
        switch (action.type) {
            case 'field':
                return {
                    ...state,
                    [action.fieldName]: action.payload,
                };
            case 'setErrors':
                return {
                    ...state,
                    errors: action.payload,
                };
            default:
                return state;
        }
    }

    const [state, dispatch] = useReducer(formReducer, initialState);

    const validateForm = () => {
        const errors = {};
        if (!state.name.trim()) errors.name = 'Ismingizni kiriting / Введите имя';
        if (!state.lastName.trim()) errors.lastName = 'Familiyangizni kiriting / Введите фамилию';
        if (!state.userID.trim()) errors.userID = `ID'ingizni kiriting / Введите ваш ID`;
        if (!state.username.trim()) errors.username = 'Telegram nikingizni kiriting / Введите ваше имя пользователя в телеграм';
        if (!state.job.trim()) errors.job = "Yo'nalishingizni kiriting / Укажите направление";
        if (state.phoneNumber.trim() === "+998" || Number.isNaN(Number(state.phoneNumber.slice(1))) || state.phoneNumber.length < 13) errors.phoneNumber = "Noto'g'ri telefon raqam / Некорректный номер телефона";
        if (!state.birthday || !(state.birthday.match(/^[0-9]*$/g)) || state.birthday.length > 2) errors.birthday = "Yoshingizni kiriting / Укажите корректный возраст";
        if (!state.gender.trim()) errors.gender = "Jinsingizni kiriting / Укажите пол";


        dispatch({ type: 'setErrors', payload: errors });
        return Object.keys(errors).length === 0;
    };

    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        dispatch({ type: 'field', fieldName: name, payload: value });
    }, []);



    const handleSubmit = useCallback(async () => {
        if (isSubmittingRef.current) {
            return;
        }

        setIsSubmitting(true);
        isSubmittingRef.current = true;
        if (validateForm()) {
            let regError = "";
            // axios.defaults.headers.common['Authorization'] = localStorage.getItem("token");

            await axios.put(`${ngrok}/api/participant/`, {
                first_name: state.name,
                last_name: state.lastName,
                username: state.username,
                code: state.userID,
                phone_number: state.phoneNumber,
                job_type_id: state.job,
                age: state.birthday,
                gender: state.gender,
            }, {
                headers: {
                    'ngrok-skip-browser-warning': true,
                }
            })
                .then((res) => {
                    /*if (isNaN(Number(res.data.user_id))) {
                        regError = res.data.user_id[0]
                        setRegistrationError(res.data.user_id[0])
                    }*/
                    console.log(res.data)
                })
                .catch((error) => {
                    console.error('Error submitting form:', error);
                    regError = error;
                    setRegistrationError(error)
                })
                .finally(() => {
                    setIsSubmitting(false);
                    isSubmittingRef.current = false;
                });
            if (regError) {
                f7.dialog.alert(regError, "Hato / Ошибка")
            }
            if (!regError){
                getState().then(
                    async (data) => {
                        const admin = await isAdmin();
                        if (admin)
                        {
                            window.Telegram.WebApp.MainButton.isVisible = false
                            f7.views.main.router.navigate("/admin")
                        }

                        if (data.state === 2) {
                            window.Telegram.WebApp.MainButton.isVisible = false
                            f7.views.main.router.navigate("/clarify")
                        } else if (data.state === 1) {
                            f7.dialog.alert("Keyinroq harakat qiling / Попробуйте позже", "Registratsiya hatosi / Ошибка при регистрации")
                        }
                    })
                    }

            //     await axios.get(`${ngrok}/api/participant/state`, {headers: {
            //             'ngrok-skip-browser-warning': true,
            //         }}).then(async (res) => {
            //
            // }

        } else {
            setIsSubmitting(false);
            isSubmittingRef.current = false;
        }
    }, [state]);

    useEffect(() => {
        const user = window.Telegram.WebApp.initDataUnsafe.user;
        if (user) {
            let telegramId = user.id;
            dispatch({ type: 'field', fieldName: 'username', payload: user.username });
        }}
    , [])

    const handlePhoneNumberChange = (e) => {
        const value = e.target.value;
        if (!value.startsWith('+998')) {
            dispatch({ type: 'field', fieldName: 'phoneNumber', payload: "+998" });
        } else {
            dispatch({ type: 'field', fieldName: 'phoneNumber', payload: value });
        }
    };


    useEffect(() => {
        const handleMainBtn = () => {
            if (!isSubmitting) {
                handleSubmit();
            }
        };

        window.Telegram.WebApp.MainButton.isVisible = true;
        window.Telegram.WebApp.MainButton.text = "Start matching";
        window.Telegram.WebApp.onEvent('mainButtonClicked', handleMainBtn);


        f7.on('pageBeforeRemove', () => {
            window.Telegram.WebApp.MainButton.isVisible = false
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
        });
        return () => {
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
        };
    }, [handleSubmit, isSubmitting])







    useEffect(() => {
        const inputs = document.querySelectorAll('input, textarea');
        inputs.forEach(input => {
            input.addEventListener('focus', () => {
                setTimeout(() => {
                    input.scrollIntoView({behavior: 'smooth'});
                }, 300);
            });
        });
    }, [])






    return (
        <Page>
            <BlockTitle style={{fontSize: 25, lineHeight: 1.2, fontWeight: 'bold'}}>Registratsiya / Регистрация</BlockTitle>
            <List strongIos dividersIos insetIos>
                <ListInput
                    name="name"
                    errorMessage={state.errors.name}
                    errorMessageForce={state.errors.name}
                    type="text"
                    value={state.name}
                    onChange={handleChange}
                    placeholder="Ism / Имя"
                />
            </List>
            <List style={{marginTop: -20}} strongIos dividersIos insetIos>
                <ListInput
                    name="lastName"
                    errorMessage={state.errors.lastName}
                    errorMessageForce={state.errors.lastName}
                    type="text"
                    value={state.lastName}
                    onChange={handleChange}
                    placeholder="Familiya / Фамилия"
                />
            </List>
            <BlockFooter>Ismingiz va familiyangizni kiriting / Имя и фамилия</BlockFooter>

            <List style={{marginTop: -20}} strongIos dividersIos insetIos>
                <ListInput
                    maxlength={2}
                    name="birthday"
                    errorMessage={state.errors.birthday}
                    errorMessageForce={state.errors.birthday}
                    value={state.birthday}
                    onChange={handleChange}
                    placeholder="Yoshingiz / Возраст"
                />
            </List>
            {/*<List strongIos dividersIos insetIos>
                <ListInput
                    name="birthday"
                    type="datepicker"
                    placeholder={"Выбрать дату"}
                    readonly
                    errorMessage={state.errors.birthday}
                    errorMessageForce={state.errors.birthday}
                    calendarParams={{
                        type:"datepicker",
                        openIn: 'customModal',
                        header: true,
                        footer: true,
                        yearPickerMin: 1950,
                        yearPickerMax: 2010,
                        locale: 'ru',
                        toolbarCloseText: 'Готово',
                        touchMove: false,
                        on: {
                            open(cal) {
                                cal.setYearMonth(1990, 1, 0);
                                cal.update();
                            },
                            change(calendar, value) {
                                handleChange(value[0].g)
                            }
                        }
                    }}
                />
            </List>*/}
            <List strongIos dividersIos insetIos>
                <ListInput
                    name="userID"
                    errorMessage={state.errors.userID}
                    errorMessageForce={state.errors.userID}
                    type="text"
                    value={state.userID}
                    onChange={handleChange}
                    placeholder="Sizga berilgan ID / Ваш ID"
                />
            </List>

            <List strongIos dividersIos insetIos>
                <ListInput
                    name="phoneNumber"
                    maxlength={13}
                    type={"tel"}
                    inputmode={"numeric"}
                    errorMessage={state.errors.phoneNumber}
                    errorMessageForce={state.errors.phoneNumber}
                    value={state.phoneNumber}
                    onChange={handlePhoneNumberChange}
                    placeholder="Raqamingiz / Номер телеофна"
                />
            </List>
            <BlockFooter>Telefon raqamingiz / Ваш номер телефона</BlockFooter>
            <List key="gender" strongIos dividersIos insetIos>
                <ListItem smartSelectParams={{ openIn: 'popover', closeOnSelect: true }} title={"Jinsingiz / Ваш пол"} smartSelect>
                    <select name={"gender"} onChange={handleChange}>
                        <option></option>
                        <option value="male">Erkak / Мужской</option>
                        <option value="female">Ayol / Женский</option>
                    </select>
                </ListItem>
            </List>
            {state.errors.gender && state.gender === "" && <BlockFooter style={{position: 'absolute',color:"#FF3B30"}}>{state.errors.gender}</BlockFooter>}

            <List key="job" style={{marginTop: 10}} strongIos dividersIos insetIos>
                <ListItem smartSelectParams={{
                    openIn: 'popup',
                    inputIconPosition: 'end',
                    cssClass: "strongIos dividersIos insetIos",
                    closeOnSelect: true,
                }}
                          title={"Направление / Yonalish"}
                          smartSelect
                >
                    <select name="job" onChange={handleChange}
                    >
                            <option></option>
                            {jobsVal?.map(item => (
                                <option
                                    key={item.id}
                                    value={item.id}>
                                    {item.name}
                                </option>))}
                    </select>
                </ListItem>
            </List>
            {state.errors.job && state.job === "" && <BlockFooter style={{position: 'absolute',color:"#FF3B30"}}>{state.errors.job}</BlockFooter>}

            <List strongIos dividersIos insetIos>
                <ListInput
                    name="description"
                    type="textarea"
                    placeholder="О вас / Siz haqingizda"
                    value={state.description}
                    onChange={handleChange}
                    maxlength={200}
                    minlength={30}
                />
            </List>
            <BlockFooter>Ozingiz haqingizda etib bering (maximum 200 soz) / Расскажите о себе (максимум 200 слов)</BlockFooter>
        <div style={{marginBottom: 120}}/>


        </Page>
    );
}

export default Registration;