import React, {useEffect} from 'react';
import {Button, f7, Page} from "framework7-react";
import {postClarify} from "../api/partner";

const Clarify = () => {
    const accept = async (value) => {
        const res = await postClarify({
            agree:value,
            stage: 1
        })
        if (res.status === 200) {
            f7.views.main.router.navigate("/splash")
        } else if (res.status === 400) {
            f7.dialog.alert(" Keyingi matchingni kuting / Дождитесь следующего мэтчинга", "Ошибка")
        }
        else {
            f7.dialog.alert("Xato! / Ошибка!","")
        }
    }

    useEffect(() => {
        window.Telegram.WebApp.MainButton.isVisible = false;
    }, [])

    return (
        <Page bgColor={"white"}>
            <p style={{display: 'flex',fontSize: '3vw', flexDirection: 'column', height: '100%', alignItems: 'center', justifyContent: 'center', paddingLeft: 10, paddingRight: 10}} className="grid grid-cols-2 grid-gap">
                <h1 className="poppins-extrabold">Matchingga tayyormisiz? </h1>
                <h1 style={{margin: 0, marginTop: -20,}} className="poppins-extrabold">Готовы к мэтчингу? </h1>
                <div className="grid grid-cols-2 grid-gap">
                    <Button style={{fontSize: '3vw'}}  color="green"   fill onClick={() => {window.Telegram.WebApp.HapticFeedback.impactOccurred("medium");accept(true)}}>
                        Boshlash / Начать
                    </Button>
                    <Button style={{fontSize: '3vw'}} color="red" fill onClick={() => {accept(false); window.Telegram.WebApp.close()}}>
                        Bekor qilish / Отмена
                    </Button>
                </div>

            </p>
        </Page>
    );
};

export default Clarify;
