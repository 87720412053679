import React, {useEffect, useState} from 'react';
import {BlockHeader, BlockTitle, Button, f7, List, ListItem, Page} from "framework7-react";
import {
    getMatchingTypes,
    getParticipantCount,
    getPartner,
    getState,
    prepareMatching,
    startMatching
} from "../api/partner";
import Confetti from "react-dom-confetti";
import female from "../assets/img_2.png";
import male from "../assets/img_1.png";
import call from "../assets/icons/call.svg";

const Admin = () => {
    const [stages, setStages] = useState([])
    const [value, setValue] = useState("")
    const [partner, setPartner] = useState(null)
    const [matchingCount, setMatchingCount] = useState(0)
    const [participantCount, setParticipantCount] = useState(0)

    const fetchData = async () => {
        const partner = await getPartner();
        const types = await getMatchingTypes();
        const currentParticipants = await getParticipantCount();
        setPartner(partner)
        setMatchingCount(currentParticipants.matching_count)
        setParticipantCount(currentParticipants.registered_count)
        setStages(types)
    }

    useEffect(() => {
        if (stages?.length === 0) {
            fetchData()
        } else {
            return;
        }
    }, [])


    const matching = async (id) => {
        const res = await startMatching(id);
        if (res.status === 200) {
            f7.dialog.alert("Мэтчинг завершился", "Успешно")
        }
        console.log("matching started: " + res)
    }

    useEffect(() => {
        const pollData = async () => {
            // const data = await getState()
            fetchData()

        };

        pollData();

        const intervalId = setInterval(pollData, 1000);

        return () => clearInterval(intervalId);

    }, [])

    const prepareMatching1 = async () => {
        const res = await prepareMatching()
        console.log(res.status)
        if (res.status === 200) {
            f7.dialog.alert("state обновился до 2", "Успешно")
        }
    }
    return (
        <Page>
            <BlockHeader>Admin panel</BlockHeader>
            <List key="ko"  strongIos dividersIos insetIos>
                <ListItem smartSelectParams={{
                    openIn: 'popup',
                    inputIconPosition: 'end',
                    cssClass: "strongIos dividersIos insetIos",
                    closeOnSelect: true,
                }}
                          title={"Направление"}
                          smartSelect
                >
                    <select onChange={(e) => {setValue(e.target.value)}} name="job"
                    >

                        {stages.map((stage) => (
                            <option value={stage.id}>
                                {stage.name}
                            </option>)
                        )}
                    </select>
                </ListItem>
            </List>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>

                <Button large onClick={() => {matching(value.value)}} fill={true}>Start matching</Button>
                <Button style={{marginTop: 20}} large onClick = {() => {prepareMatching1()}} fill={true}>Prepare</Button>
                <BlockTitle>Current participant count: {participantCount}</BlockTitle>
                <BlockTitle style={{marginTop: -10}}>Current matching count: {matchingCount}</BlockTitle>
            </div>

                <BlockTitle>My parnters</BlockTitle>
            {partner && <div style={{marginTop: -20}} bgColor={"white"}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100vw',
                        marginTop: 20,
                        padding: 0,
                    }}>
                        <div
                            style={{
                                width: 150,  // Set width and height to be equal for a perfect circle
                                height: 150,
                                backgroundImage: `url(${partner?.gender === "female" ? female : male})`,
                                borderRadius: "50%",  // This makes it circular
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.4)',
                            }}
                        />
                    </div>


                    <h1 style={{marginLeft: 10, fontSize: 30, color: "#2ea4ee"}}>
                        <span className={"poppins-extrabold"}>{partner?.first_name},</span>
                        <span className={"poppins-extrathin"} style={{fontSize: 25, color: "#92b7ee"}}>{" "}{partner?.age}</span>
                    </h1>

                    <p style={{marginLeft: 10,fontSize: 16}}>
                        <span style={{fontWeight: 'bold'}} className={"poppins-regular"}>Bu ID bo'yicha toping: </span>
                        <span style={{fontSize: 16, color:'#06933b'}} className={"poppins-regular"}> {partner?.code} </span>
                    </p>
                    <p style={{marginLeft: 10,fontSize: 16}} className={"poppins-regular"}>
                        <span style={{fontWeight: 'bold'}}>Yo'nalish: </span> {partner?.job_type_id}
                    </p>
                    <p style={{marginLeft: 10,fontSize: 16}} className={"poppins-regular"}>
                        <span style={{fontWeight: 'bold'}}>Telefon raqam: </span> {partner?.phone_number}
                        <img
                            onClick={() => {
                                window.Telegram.WebApp.HapticFeedback.impactOccurred("medium");
                                window.open(`tel:${partner?.phone_number}`);}}
                            style={{verticalAlign: 'text-bottom',marginTop: -3,marginLeft: 5}}
                            width={25}
                            src={call}
                        />
                    </p>

                    <h1 className={"poppins-bold"} style={{
                        marginLeft: 10,
                        fontSize: 15
                    }}>
                        Men haqimda
                    </h1>
                    <p className={"poppins-regular"} style={{
                        color: 'black',
                        marginLeft: 10,
                        fontSize: 15,
                        overflow: 'visible',
                        width: '90vw',
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',}}>{partner?.description ? partner.description : "M'alumot yoq"}
                    </p>

                </div>}

        </Page>
    );
};

export default Admin;

